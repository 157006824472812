@import 'bootstrap/scss/bootstrap.scss';

#textEncodeApp {
    padding: 1em;
}

#textEncodeApp > div{
    margin-right: auto;
    margin-left: auto;
    max-width: fit-content;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: flex-start;
    background-color: hsl(17, 100%, 92%);
    border: 1px black solid;
    border-radius: 5px;
}
#textEncodeApp > div > span{
    padding: 10px;

}

#textEncodeApp button {
    margin-top: 5px;
    margin-left: 10px;
    // margin-right:;
    // margin-left: auto;
}
#textEncodeApp input[type=radio] {
    margin: 5px;
}

#textEncodeInput{
    width: 15em;
    height: 2em;
    padding: 3px;
    border: 1px gray solid;
    border-radius: 3px;
    margin: 5px;
   }

#textEncodeOutput{
    width: 15em;
    height: 2em;
    padding: 3px;
    border: 1px gray solid;
    border-radius: 3px;
    margin: 5px;
    background-color: azure;
    overflow: scroll;
}

